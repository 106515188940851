<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabDayaDukungTypeClick">
        DayaDukung Type
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FDayaDukungTypeTable ref="refTabDayaDukungTypeClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>


    </v-tabs>

  </v-card>
</template>

<script>
import FDayaDukungTypeTable from "../../components/admin_dayadukung/jenis/FDayaDukungTypeTable";

export default {
  name: "DayaDukungType",
  components: {
    FDayaDukungTypeTable
  },
  data() {
    return {
      firstTabTitle: 'DayaDukungType',
      firstTabIcon: 'mdi-file-tree'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabDayaDukungTypeClick(){
      try {
        // this.$refs.refTabDayaDukungTypeClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },


  }
}
</script>

<style scoped>
</style>